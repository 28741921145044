/* BEGIN TRANSLATION */

const $vuetify = {
    noDataText: "无数据可显示",
    dataTable: {
        itemsPerPageText: "记录数",
        sortBy: "排序方式",
        ariaLabel: {
            sortNone: "没有排序",
            sortAscending: "升序排列",
            sortDescending: "降序排列",
            activateNone: "无",
            activateAscending: "升序排列",
            activateDescending: "降序排列"
        }
    },
    dataFooter: {
        prevPage: "上一页",
        nextPage: "下一页",
        pageText: "{0}-{1}共{2}",
        itemsPerPageAll: "全部"
    },
    dataIterator: {
        noResultsText: "未能找到匹配的记录",
        loadingText: "载入中..."
    },
    fileInput: {
        counter: "{0}个文件",
        counterSize: "{0}个文件（共{1}）"
    },
    pagination: {
        ariaLabel: {
            currentPage: "当前页，第{0}页",
            next: "下一页",
            previous: "上一页",
            wrapper: "分页导航",
            page: "转至页面{0}"
        }
    },
    carousel: {
        ariaLabel: {
            delimiter: "{1}的轮播幻灯片{0}"
        },
        prev: "以前的视觉",
        next: "下一个视觉"
    },
    datePicker: {
        prevMonthAriaLabel: "上个月",
        nextMonthAriaLabel: "下个月"
    },
    timePicker: {
        am: "上午",
        pm: "下午"
    }
};

/* ENDED TRANSLATION */
export { $vuetify };